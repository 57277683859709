import { ServiceType } from '.';
import { BrokeredCustomerSettings, defaultBrokeredCustomerSettings } from './brokered-customer-settings';
import { CustomerContact } from './customer-contact';
import { CustomerScacFuelProgram } from './customer-scac-fuel-program';
import { FuelProgramTypeEnum } from './FuelProgramType';

export interface CustomerProfile {
  customerId: string;
  name: string;
  defaultCommodity: string;
  useFuelRerating: boolean;
  fuelReratingNumberOfDays: number;
  allowZeroFuel: boolean;
  allowEditingFuel: boolean;
  topsOwnerId: string;
  successManagerUserId: string;
  successSpecialistUserId: string;
  comments: string;
  dataSource: string;
  customerLoadTypeId: string;
  customerLoadTypeExpirationDate: any;
  customerContacts: CustomerContact[];
  customerCarrierScacs: string[];
  customerCarrierScacExcluded: string[];
  autoPostLoad: boolean;
  validateUniqueReferenceLoadIds: boolean;
  identUserSetup: boolean;
  allowManualLoadCreation: boolean;
  inNetworkFlatFee: number;
  inNetworkPercentFee: number;
  outNetworkFlatFee: number;
  outNetworkPercentFee: number;
  inNetworkFeeAdd: boolean;
  outNetworkFeeAdd: boolean;
  requireMarginalAnalysis: boolean;
  deleteLoadCreatedOnTops: boolean;
  useDefaultContact: boolean;
  useLoadshopAccessorials: boolean;
  requiredServiceTypeIds: number[];
  isChatEnabled: boolean;
  isActive: boolean;
  allowCounterOffer: boolean;
  applyCounterOfferToAllLoads: boolean;
  prefetchContractRates: boolean;
  newLoadDaysInPast: number;
  selectedServiceTypeIds: number[];
  availableServiceTypes: ServiceType[];
  allowEditingBookedManualLoads: boolean;
  brokeredLoads: boolean;
  brokeredCustomerSettings?: BrokeredCustomerSettings;
  quoteEligibleLanesCount: number;
  fuelProgramType?: FuelProgramTypeEnum;
  customerScacFuelPrograms?: CustomerScacFuelProgram[];
  contractCarriersBookAtPostedRate: boolean;
  externalCustomerId: string;
  sspTotalRateAdjustmentPercentage?: number;
  isPlatform: boolean;
}

export const defaultCustomerProfile: CustomerProfile = {
  customerId: null,
  name: null,
  defaultCommodity: null,
  useFuelRerating: false,
  fuelReratingNumberOfDays: null,
  allowZeroFuel: false,
  allowEditingFuel: false,
  topsOwnerId: null,
  successManagerUserId: null,
  successSpecialistUserId: null,
  comments: null,
  dataSource: 'LOADSHOP',
  customerLoadTypeId: null,
  customerLoadTypeExpirationDate: null,
  customerContacts: [],
  customerCarrierScacs: [],
  customerCarrierScacExcluded: [],
  autoPostLoad: false,
  validateUniqueReferenceLoadIds: false,
  identUserSetup: false,
  allowManualLoadCreation: false,
  inNetworkFlatFee: null,
  inNetworkPercentFee: null,
  outNetworkFlatFee: null,
  outNetworkPercentFee: null,
  inNetworkFeeAdd: null,
  outNetworkFeeAdd: null,
  requireMarginalAnalysis: false,
  deleteLoadCreatedOnTops: false,
  useDefaultContact: false,
  useLoadshopAccessorials: true,
  requiredServiceTypeIds: [],
  isChatEnabled: false,
  isActive: false,
  allowCounterOffer: false,
  applyCounterOfferToAllLoads: false,
  prefetchContractRates: false,
  newLoadDaysInPast: 0,
  selectedServiceTypeIds: [],
  availableServiceTypes: [],
  allowEditingBookedManualLoads: false,
  brokeredLoads: false,
  brokeredCustomerSettings: defaultBrokeredCustomerSettings,
  quoteEligibleLanesCount: 0,
  fuelProgramType: null,
  contractCarriersBookAtPostedRate: false,
  externalCustomerId: null,
  sspTotalRateAdjustmentPercentage: null,
  isPlatform: false,
};
